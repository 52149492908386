if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((reg) => {
      if (!reg.active?.scriptURL.endsWith("js/xf/service_worker.js")) {
        reg.unregister().then((success) => {
          if (success) {
            // eslint-disable-next-line no-console
            console.log("Old service worker unregistered");
          }
        });
      }
    });
  });
}
